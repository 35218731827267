import defaultLocale from '../locales/locale.json';

const state = {
  locale: {
    current: defaultLocale.selectedLanguage,
    translations: defaultLocale.locale,
  },
  coins: {
    list: [],
    loading: false,
    error: false,
    errorInfo: {type: '', prevAction: '', response: ''},
  },
  order: {
    info: {
      id: '',
      address: '',
      cost: 0,
      amount: 0,
      currency: '',
      email: '',
      user_id: '',
      order_id: '',
      status: 'empty',
      product_name: '',
      site_name: '',
      created_at: '',
      expired_at: '',
      url_return: '',
    },
    firstLoading: true,
    timeLeft: 0,
    currencyLoading: false,
    error: false,
    errorInfo: {type: '', prevAction: {type: '', payload: false}, response: ''},
    theme: 'default',
    shouldOpenPopup: true
  },
  serviceMessage: {
    loading: '',
    error: '',
    errorInfo: {type: '', prevAction: {type: '', payload: false}, response: ''},
  },
};

export default state;
