import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';
import {Link} from '../../atoms/link';
import {localizedOrNot} from "../../../environment/mixins";


const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts.sizes.md}
  
   /*prettier-ignore */
    ${Text}:nth-child(1),
    ${Text}:nth-child(2){
      margin-right: 1em;
    }
`;

export default class NavigationMenu extends Component {
  render() {
    
    return (
      <StyledWrapper>
        <Text bold="true" blue="true">
          <Link template={this.props.template} to="/support">{localizedOrNot('base.linkTitle.support')}</Link>
        </Text>
        <Text bold="true" blue="true">
          <Link template={this.props.template} to="/terms">{localizedOrNot('base.linkTitle.terms')}</Link>
        </Text>
        <Text bold="true" blue="true">
          <Link template={this.props.template} to="/privacy">{localizedOrNot('base.linkTitle.privacy')}</Link>
        </Text>
      </StyledWrapper>
    );
  }
}
