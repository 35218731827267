import React, {Component, Fragment} from 'react';
import styled, {keyframes} from 'styled-components';

import Info from '../../molecules/info';
import Timer from '../../molecules/timer';
import ImagesContainer from '../../molecules/imagesContainer';
import ConfirmationText from '../../molecules/confirmationText';

import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";
import Processing from "../processing_body";
import Text from "../../atoms/text";
import Amounts from "../../molecules/amounts";
import {COPY, HIDEQR, SHOWQR} from "../../atoms/svg";
import {Popup} from "semantic-ui-react";
import {localizedOrNot} from "../../../environment/mixins";


const FormWrapper = styled.div`
  display: flex;
`;

const AddressWrapper = styled.div`
  margin: 0;
  max-width: 100%;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  text-align: left;
  line-height: 1.21428571em;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: rgba(0,0,0,.87);
  transition: box-shadow .1s ease,border-color .1s ease;
  box-shadow: none;
  color: #a2a2a2;
  background-color: #f6f6f6;
  border: 0.63rem;
  font-size: 1.26rem;
  font-weight: 400;
  border: none;
  border-radius: 5px 0 0 5px;
  text-overflow: ellipsis;
  padding: 1rem;
  word-break: break-all;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  padding: 1rem;
  background-color: #eaeaea;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-around;

  & .qrAction{
    cursor:pointer
  }
`;

const TimerWrapper = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  &&:empty {
    padding-top: 0;
  }
`;

const TextWrapper = styled.div`
  margin: 0.5rem 0 2.5rem;
`;

const StyledPopup = styled(Popup)`
  &&& {
    border-radius: 5px;
    font-weight: 600;
    color: #a2a2a2;
    padding: 0.3em 0.9em;

    .content {
      display: block;
      font-size: 0.65rem;
    }

    border: 1px solid white;
    box-shadow: 0 1px 8px #0000001a;
    opacity: 1;
    animation: ${keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `} 0.2s linear;
    animation-delay: 0.8s;
  }
`;

class ModalBody extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCopyAddress: false,
            isShowQRCode: false
        };
    }

    copyToClipboard = value => {
        navigator.clipboard.writeText(value).then();
    };

    toggleQR = () => {
        this.setState({isShowQRCode: !this.state.isShowQRCode});
    };
    handleOpenPopup = (id, value) => e => {
        this.copyToClipboard(value)
        this.setState({[id]: true});
        this.timeout = setTimeout(() => {
            this.setState({[id]: false});
            clearTimeout(this.timeout);
        }, 1000);
    };

    render() {
        const {isCopyAddress, isShowQRCode} = this.state;
        const {order, timeLeft, template, currentTime} = this.props;
        const delta = order.timestamp > currentTime
        return (
            <Fragment>
                <Info modal="true"/>
                {(order.status === 'pending' || order.status === 'expired') && CRYPTO_CURRENCIES.includes(order.currency) && (
                    <Fragment>
                        <Text whiteSpace="normal" grey="true" fontSize="0.7rem">
                            {localizedOrNot('modals.copyAmountToWalletText')}
                        </Text>
                        <Amounts/>
                        {order.status !== 'expired' &&
                            (
                                <Text whiteSpace="normal" grey="true" fontSize="0.7rem">
                                    {localizedOrNot('modals.copyAddressToWalletText').split('{0}')[0]}
                                    <SHOWQR width="0.8rem" height="0.8rem" inline={true}/>
                                    {localizedOrNot('modals.copyAddressToWalletText').split('{0}')[1]}
                                </Text>
                            )
                        }
                        <FormWrapper>
                            <AddressWrapper>{order.address}</AddressWrapper>
                            <ActionsWrapper>
                                <StyledPopup
                                    trigger={<a><COPY width="20px" height="20px"/></a>}
                                    content={localizedOrNot('base.copied')}
                                    on="click"
                                    size="mini"
                                    basic
                                    position="top center"
                                    open={isCopyAddress}
                                    onOpen={this.handleOpenPopup('isCopyAddress', order.address)}
                                />
                                {order.status !== 'expired' && !isShowQRCode && <a title={localizedOrNot('modals.qr.show')} onClick={this.toggleQR}><SHOWQR width="20px" height="20px"/></a>}
                                {order.status !== 'expired' && isShowQRCode && <a title={localizedOrNot('modals.qr.hide')} onClick={this.toggleQR}><HIDEQR width="20px" height="20px"/></a>}
                            </ActionsWrapper>
                        </FormWrapper>
                    </Fragment>
                )}
                {order.status === 'processing' && delta && (
                    <Fragment>
                        <Processing template={template} error={localizedOrNot('modals.status.processing.text')}/>
                        <Amounts/>
                        <FormWrapper>
                            <AddressWrapper>{order.address}</AddressWrapper>
                            <ActionsWrapper>
                                <StyledPopup
                                    trigger={<a><COPY width="20px" height="20px"/></a>}
                                    content={localizedOrNot('base.copied')}
                                    on="click"
                                    size="mini"
                                    basic
                                    position="top center"
                                    open={isCopyAddress}
                                    onOpen={this.handleOpenPopup('isCopyAddress', order.address)}
                                />
                                {!isShowQRCode && <a title={localizedOrNot('modals.qr.show')} onClick={this.toggleQR}><SHOWQR width="20px" height="20px"/></a>}
                                {isShowQRCode && <a title={localizedOrNot('modals.qr.hide')} onClick={this.toggleQR}><HIDEQR width="20px" height="20px"/></a>}
                            </ActionsWrapper>
                        </FormWrapper>
                    </Fragment>
                )}
                {isShowQRCode && (order.status === 'pending' || order.status === 'processing') && delta && <ImagesContainer onlyQR={true}/>}
                {(order.status === 'expired' || !delta) && <ImagesContainer expired={true}/>}
                {order.status === 'pending' && delta && <Text whiteSpace="normal" blue="true" fontSize="1rem" bold="true">{localizedOrNot('modals.status.pending.text')}</Text>}
                {((order.status === 'pending' && CRYPTO_CURRENCIES.includes(order.currency) ||
                    order.status === 'processing') && delta) && (
                    <TimerWrapper>
                        <Text lightGrey="true" medium="true" fontSize="1.38rem">
                            {localizedOrNot('modals.status.timer')}
                        </Text>
                        {timeLeft > 0 && delta && <Timer/>}
                    </TimerWrapper>
                )}
                {(order.status === 'expired' || !delta) && (
                    <TimerWrapper>
                        <Text lightGrey="true" medium="true" fontSize="1.38rem">
                            {localizedOrNot('modals.status.timer')} 00:00:00
                        </Text>
                    </TimerWrapper>
                )}
                {order.status === 'successful' && (
                    <Fragment>
                        <ImagesContainer template={template} success={true}/>
                        <TextWrapper>
                            <ConfirmationText template={template}>{localizedOrNot('modals.status.successful.text')}</ConfirmationText>
                        </TextWrapper>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(ModalBody);

ModalBody.propTypes = {
    modal: PropTypes.any,
    order: PropTypes.shape({
        id: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        cost: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
        email: PropTypes.string.isRequired,
        user_id: PropTypes.string.isRequired,
        order_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        product_name: PropTypes.string.isRequired,
        site_name: PropTypes.string.isRequired,
        created_at: PropTypes.string.isRequired,
        expired_at: PropTypes.string.isRequired,
        url_return: PropTypes.string.isRequired,
    }).isRequired,
    timeLeft: PropTypes.number,
};
