import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Dropdown, Icon} from 'semantic-ui-react';
import { setLocale } from './actions';
import languages from '../../../locales/languages.json';

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};
  margin-right:0.5rem !important;
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    .dropdown:before {
      margin-left: 1.3vw;
      color: ${props => props.theme.colors.text.primary};
    }
    .menu{
      @media (max-width: 767px) { 
        left: -15px;
       }
     }
    .icon {
      transform: ${props =>
        props.rotate === "true" ? 'rotate(-180deg) translateX(-1.3vw)' : 'rotate(0deg)'};
    }
    .text {
      color: ${props => props.theme.colors.text.primary};
      font-size: ${props => props.theme.fonts.sizes.sm};
      font-weight: ${props => props.theme.fonts.weights.semibold};
    }
  }
`;

class LangDropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: "false",
      data: languages,
    };
  }

  handleChange = async (e, { value }) => {
    try {
      const localeURL = `${process.env.REACT_APP_API_URL}lang/`;
      const response = await fetch(`${localeURL}${value}`);
      if (response.ok) {
        const data = await response.json();
        this.props.setLocale(value, data.locale);
      }
    } catch {}
  };

  render() {
    const { data } = this.state;
    const { currentLanguage } = this.props;
    const selectedLanguage = data.find(lang => lang.key === currentLanguage)?.value || data[0].value;
    return (
      <div>
        <StyledIcon name="globe" size="large" />
        <StyledDropdown
          inline
          options={data}
          value={selectedLanguage}
          rotate={this.state.rotate.toString()}
          onClick={() => {
            this.setState({rotate: this.state.rotate.toString() === "true" ? "false" : "true"});
          }}
          onChange={this.handleChange}
          closeOnEscape={true}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentLanguage: state.locale.current,
});
const mapDispatchToProps = {setLocale};
export default connect(mapStateToProps, mapDispatchToProps)(LangDropDownMenu);