import React, {Component, Fragment} from 'react';

import Body from '../../organisms/body';
import Loader from '../../atoms/loader';

import {connect} from 'react-redux';
import {mapStateToProps, mapDispatchToProps} from './container';
import PropTypes from 'prop-types';
import 'semantic-ui-css/semantic.min.css';
import * as errorTypes from '../../../logic/order/errorTypes';
import Error from '../../organisms/error_body';
import ErrorBoundary from '../../errorBoundary';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";



class CoinChoicePage extends Component {
  componentDidMount() {
    const {firstLoadOrder} = this.props;
    firstLoadOrder();
  }
  componentDidUpdate(prevProps) {
    //Todo: webSocket

    // if (this.props.ws === 'failed') {
    //     this.props.innitWS();
    //  }

    document.title = "CoinCentro|order:"+ this.props.order.id;
    const {order, loadCoins} = this.props;
    if (
      (prevProps.order.status !== order.status ||
        prevProps.order.currency !== order.currency) &&
      order.status === 'pending' &&
        (order.currency === EMPTY_CURRENCY || NOT_CRYPTO_CURRENCIES.includes(order.currency))
    ) {
      loadCoins();
    }
    if (
      prevProps.order.status !== order.status &&
      order.status !== 'failed' &&
      order.status !== 'expired' &&
        order.status !== 'canceled' &&
      order.status !== 'successful'
    ) {
      //Todo: webSocket
      //this.props.innitWS();
      this.props.loadOrder();
    }
    if (
      prevProps.order.status !== order.status &&
      (order.status === 'failed' ||
        order.status === 'expired' ||
          order.status === 'canceled' ||
        order.status === 'successful')
    ) {

    }
  }

  render() {
    const {firstLoading, sendErrorMessage, orderError, orderErrorInfo, coinsError, coinsErrorInfo, template} = this.props;
    return (
      <Fragment>
        {((orderError === false && coinsError === false) ||
          (orderError === true && orderErrorInfo.type === errorTypes.NETWORK_ERROR) ||
          (coinsError === true && coinsErrorInfo.type === errorTypes.NETWORK_ERROR)) && (
          <ErrorBoundary template={template} sendErrorMessage={sendErrorMessage}>
            {firstLoading &&
              <Loader template={template} />
            }
            {!firstLoading &&
                <Body template={template} />
            }
          </ErrorBoundary>
        )}
        {orderError === true &&
        orderErrorInfo.type !== errorTypes.NETWORK_ERROR && (
            <Error template={template} error={orderErrorInfo.type} />
        )}
        {coinsError === true &&
        coinsErrorInfo.type !== errorTypes.NETWORK_ERROR && (
            <Error template={template} error={coinsErrorInfo.type} />
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoinChoicePage);

CoinChoicePage.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    site_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expired_at: PropTypes.string.isRequired,
    url_return: PropTypes.string.isRequired,
  }).isRequired,
  firstLoading: PropTypes.bool,
  firstLoadOrder: PropTypes.func,
  loadCoins: PropTypes.func,
  loadOrder: PropTypes.func,
};
