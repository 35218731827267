import React from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';
import {localizedOrNot} from "../../../environment/mixins";


const StyledWrapper = styled.div`
    padding: 2vh;
    margin: 1vw 0;
    min-height: 90px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 1023px) and (orientation:landscape) {
        padding: 2vw 0;
    }
    background-color: ${props => props.template === 'fc' ? '#F7F7FA' : props.theme.colors.bg.secondary};`;

const ProcessingBody = props => {
  const {template} = props
  return (
    <StyledWrapper template={template}>
      <Text whiteSpace="normal" template={template} bold="true" grey="true" fontSize="0.8rem">
        {props.error}
      </Text>
      <Text whiteSpace="normal" template={template} grey="true" fontSize="0.75rem">{localizedOrNot('processingDescription')}</Text>
    </StyledWrapper>
  );
};

export default ProcessingBody;
