import React, {Component} from 'react';
import styled from 'styled-components';

import TitleText from '../../../../molecules/titleText';
import PlainText from '../../../../molecules/plainText';
import PropTypes from 'prop-types';
import {localizedOrNot} from "../../../../../environment/mixins";

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2em 1em;
  align-content: center;
`;

const TitleTextWrapper = styled.div`
  margin-bottom: 1.5em;
`;

export default class FailedSubModal extends Component {
  render() {
    return (
      <ConfirmationWrapper>
        <TitleTextWrapper>
          <TitleText>{localizedOrNot('modals.failed.title')}</TitleText>
        </TitleTextWrapper>
        <PlainText>{
            this.props.status === 'canceled' ?
            localizedOrNot('modals.failed.description.canceled') :
            localizedOrNot('modals.failed.description') + " " + this.props.status
        }</PlainText>
      </ConfirmationWrapper>
    );
  }
}

FailedSubModal.propTypes = {
  closeModal: PropTypes.func,
};
