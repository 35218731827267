import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';
import {localizedOrNot} from "../../../environment/mixins";


const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export default class OrderProcessingNoteText extends Component {
    render() {
        
        return (
            <StyledWrapper>
                <Text whiteSpace="normal" grey="true" fontSize="0.7rem">
                    {localizedOrNot('orderProcessingText.doNotClose')}
                </Text>
                <Text whiteSpace="normal" grey="true" fontSize="0.7rem">
                    {localizedOrNot('orderProcessingText.few')}
                </Text>
            </StyledWrapper>
        );
    }
}

