import defaultState from '../defaultState';

export function localeReducer(state = defaultState.locale, action) {
    switch (action.type) {
        case 'SET_LOCALE':
            return {
                ...state,
                current: action.payload.locale,
                translations: action.payload.translations,
            };
        default: {
            return {
                ...state,
            };
        }
    }
}


