export function mapStateToProps(state) {
  const {order, service} = state;
  return {
    order: order.info,
    orderError: order.error,
    orderErrorInfo: order.errorInfo,
    serviceMessageError: service.error,
    serviceMessageErrorInfo: service.errorInfo
  };
}
