import {combineReducers} from 'redux';

import {coinsReducer, orderReducer} from './order/reducer';
import {serviceReducer} from './serviceMessage/reducer';
import {localeReducer} from "./locale/localeReducer";

export default combineReducers({
  coins: coinsReducer,
  order: orderReducer,
  service: serviceReducer,
  locale: localeReducer
});
