import React, {Component, Fragment} from 'react';
import styled, {keyframes} from 'styled-components';

import TitleText from '../../molecules/titleText';
import Text from '../../molecules/plainText';

import * as errorTypes from '../../../logic/order/errorTypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import {localizedOrNot} from "../../../environment/mixins";


const SECOND = 1000;

const openMessage = keyframes`
    from {
            opacity: 0;
            transform: translateY(-50px)
        }
        to {
            opacity: 1;
            transform: translateY(0px)
        }
`;

const closeMessage = keyframes`
    from {
            opacity: 1;
            transform: translateY(0px)
            
        }
        to {
            opacity: 0;
            transform: translateY(-50px)
        }
`;

const StyledWrapper = styled.div`
  width: 98%;
  background-color: ${props => props.theme.colors.bg.octonary};
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 1%;
  right: 1%;
  z-index: 111;
  animation: 1s ease-out 0s;
  animation-name: ${props => (props.internet ? closeMessage : openMessage)};
`;

const TitleWrapper = styled.div`
  margin: 2rem 0 0 0;
`

class ModalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internet: true,
      messageOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (
        (this.props.orderError === true &&
          this.props.orderErrorInfo.type === errorTypes.NETWORK_ERROR) ||
        (this.props.serviceMessageError === true &&
          this.props.serviceMessageErrorInfo.type === errorTypes.NETWORK_ERROR)
      ) {
        this.setState({internet: false, messageOpen: true});
      } else {
        this.setState({internet: true, timeout: setTimeout(() => this.setState({messageOpen: false}), SECOND)})
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  render() {
    const {messageOpen, internet} = this.state;
    const {template} = this.props
    return (
      <Fragment>
        {messageOpen && (
          <StyledWrapper internet={internet}>
            <Text template={template}>{localizedOrNot('base.error.connect')}</Text>
          </StyledWrapper>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(ModalHeader);

ModalHeader.propTypes = {
  orderError: PropTypes.bool.isRequired,
  orderErrorInfo: PropTypes.shape({
    type: PropTypes.string,
    prevAction: PropTypes.shape({
      type: PropTypes.string,
      payload: PropTypes.any,
    }),
    response: PropTypes.any,
  }).isRequired,
};
