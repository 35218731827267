import React, {Component} from 'react';

import 'semantic-ui-css/semantic.min.css';
import TitleText from '../../molecules/titleText';
import {localizedOrNot} from "../../../environment/mixins";

class SupportPage extends Component {
  componentDidMount() {
    document.title = "CoinCentro|support";
  }
  render() {
    
    return <TitleText>{localizedOrNot('base.document.title.support')}</TitleText>;
  }
}

export default SupportPage;
