import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Page from './components/pages/index';
import GlobalStyles from './global_styles/index';
import {Router} from 'react-router-dom';
import history from "./history";
import './environment/stringFormatter';
import localeData from './locales/locale.json';
import languages from './locales/languages.json';
import {setLocale} from "./components/molecules/langDropDownMenu/actions";

function App() {
    const dispatch = useDispatch();
    function updateLocale(language) {
        const localeURL = `${process.env.REACT_APP_API_URL}lang/`;
        fetch(`${localeURL}${language}`)
            .then(response => response.ok ? response.json() : null)
            .then(data => {
                if (data) {
                    dispatch(setLocale(language, data.locale));
                }
            })
            .catch(error => console.error("Failed to fetch language data", error));
    }

    function checkLocale() {
        const browserLanguage = navigator.language.slice(0, 2);
        const selectedLanguage = localeData.selectedLanguage;
        const availableLanguages = languages.map(lang => lang.value);
        if (browserLanguage !== selectedLanguage && availableLanguages.includes(browserLanguage)) {
            updateLocale(browserLanguage);
        }
    }

    useEffect(() => {
        checkLocale();
    }, []);

    return (
        <Router history={history}>
            <GlobalStyles/>
            <Page/>
        </Router>
    );
}

export default App;
