import React, {Component} from 'react';

import 'semantic-ui-css/semantic.min.css';
import TitleText from '../../molecules/titleText';
import {localizedOrNot} from "../../../environment/mixins";

class PrivacyPage extends Component {
  componentDidMount() {
    document.title = "CoinCentro|privacy";
  }

  render() {
    
    return <TitleText>{localizedOrNot('base.document.title.privacy')}</TitleText>;
  }
}
export default PrivacyPage;
