import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';
import {localizedOrNot} from "../../../environment/mixins";


const StyledWrapper = styled.div`
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 2rem;
  margin: 1.5vh 0 0;

  a {
    color: #6b6d72;
    text-decoration: underline;

    &:hover {
      color: #008F8C;
    }
  }
`;

export default class TimeExpiredNoteText extends Component {
  render() {
    
    return (
        <StyledWrapper>
          <Text template={this.props.template} whiteSpace="normal" grey="true" fontSize="1rem" dangerouslySetInnerHTML={{ __html: localizedOrNot('timeExpiredNoteText') }}></Text>
        </StyledWrapper>
    );
  }
}

