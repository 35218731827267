import React, {Component, Fragment} from 'react';
import styled, {keyframes} from 'styled-components';

import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import PropTypes from 'prop-types';
import {
    CRYPTO_CURRENCIES,
    CRYPTO_CURRENCIES_LIST,
    EMPTY_CURRENCY,
    NOT_CRYPTO_CURRENCIES
} from "../../../environment/supported_currencies";
import {COPY} from "../../atoms/svg";
import {Popup} from "semantic-ui-react";
import {localizedOrNot} from "../../../environment/mixins";


const StyledWrapper = styled.div`
  color: #6b6d72;
  display: grid;
  grid-template-columns: auto auto;
  padding: 1.25rem;
  background: rgba(200, 200, 200, 0.1);
  border-radius: 6px;
  font-size: 0.8rem;

  & .inner-title {
    text-align: left;
    padding: 0.5rem 0.5rem 0.5rem 0;
    border-bottom: 1px dotted #999999;
    border-right: 1px dotted #999999;
  }

  & .inner-price {
    text-align: left;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border-bottom: 1px dotted #999999;
  }

  & .copy.icon.outline {
    color: #008F8C;
    font-size: 1rem;
    margin-left: 0.2rem;

    &:before {
      cursor: pointer;
      top: 0.1rem;
    }
  }

  & .bold {
    font-weight: normal;
  }

  & .top {
    padding-top: 0;
  }
  
  & .colspan {
    grid-column: 1 / span 2;
    text-align: center;
    font-weight: bold;
  }

  & .bottom {
    border-bottom: none;
    padding-bottom: 0;
    font-size:0.95rem;
  }
  
  & .due {
    display: flex;
    gap: 0.3rem;
    justify-content: space-between;
    align-items: center;
  }

  & .qrAction{
    cursor:pointer
  }
`;

const StyledPopup = styled(Popup)`
  &&& {
    border-radius: 5px;
    font-weight: 600;
    color: #a2a2a2;
    padding: 0.3em 0.9em;

    .content {
      display: block;
      font-size: 0.65rem;
    }

    border: 1px solid white;
    box-shadow: 0 1px 8px #0000001a;
    opacity: 1;
    animation: ${keyframes`
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    `} 0.2s linear;
    animation-delay: 0.8s;
  }
`;


class Amounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCopyDue: false,
        };
    }

    copyToClipboard = value => {
        navigator.clipboard.writeText(value).then();
    };

    handleOpenPopup = (id, value) => e => {
        this.copyToClipboard(value)
        this.setState({[id]: true});
        this.timeout = setTimeout(() => {
            this.setState({[id]: false});
            clearTimeout(this.timeout);
        }, 1000);
    };

    render() {
        const {isCopyDue} = this.state;
        const {order} = this.props;
        let currency = order['currency'] === EMPTY_CURRENCY ? 'USD' : order['currency'];
        const currency_title = CRYPTO_CURRENCIES_LIST[currency].longTitle
        return (
            <Fragment>

                <StyledWrapper>
                    <div className="inner-price top colspan">{currency_title}</div>
                    <div className="inner-title">{localizedOrNot('amount.priceCost')}</div>
                    <div className="inner-price">{order['cost']}</div>
                    <div className="inner-title">{localizedOrNot('amount.priceAmount')}</div>
                    <div className="inner-price">{order['amount']}</div>
                    <div className="inner-title bold bottom">{localizedOrNot('amount.expDate')}</div>
                    <div className="inner-price bold bottom due">
                        <span>{Number((order['cost'] - order['amount']).toFixed(9))}</span>
                        <StyledPopup
                            trigger={<a><COPY width="20px" height="20px"/></a>}
                            content={localizedOrNot('base.copied')}
                            on="click"
                            size="mini"
                            basic
                            position="bottom center"
                            open={isCopyDue}
                            onOpen={this.handleOpenPopup('isCopyDue', Number((order['cost'] - order['amount']).toFixed(9)) )}
                        />
                    </div>
                </StyledWrapper>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(Amounts);

Amounts.propTypes = {
    order: PropTypes.shape({
        id: PropTypes.string,
        address: PropTypes.string,
        cost: PropTypes.number,
        amount: PropTypes.number,
        currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
        email: PropTypes.string,
        user_id: PropTypes.string,
        order_id: PropTypes.string,
        status: PropTypes.string,
        product_name: PropTypes.string,
        site_name: PropTypes.string,
        created_at: PropTypes.string,
        expired_at: PropTypes.string,
        url_return: PropTypes.string.isRequired,
    }),
    onlyQR: PropTypes.any,
    QRandSpinner: PropTypes.any,
    success: PropTypes.any,
};
